import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Map from "../../static/img/svg/map.svg"
import MapKey from "../../static/img/svg/map-key.svg"
import MapNorth from "../../static/img/svg/map-north.svg"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'

export default function Home() {
  return (

    <PageTransition
    transitionStyles={{
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    }}
  >
      <div className="wrapper">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Governors Point</title>
          <meta name="description" content="Governors point is a 125-acre peninsula with a 98-acre nature reserve and 16 homesites, located at the base of Chuckanut mountain and is surrounded by the Salish sea, one of the world’s largest and most ecologically rich inland seas." />
          <link rel="canonical" href="https://governorspoint.com" />
          <body className={isMobile ? `home mobile` : `home`} />
        </Helmet>

        <Header menuActive="" />

        <div className="main">

          <div className="module banner">
            <figure><img src="/img/home-banner.jpg" alt="" /></figure>
            <div className="container">
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Above</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Eget tincidunt enim pretium cras accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/home-01.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/home-02.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img video-2">
              <div className="video-wrap" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <iframe src="https://player.vimeo.com/video/556916696?h=10d3e84af0&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="video" allowfullscreen></iframe>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/home-03.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/home-04.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/home-05.jpg" alt="" /></figure>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Above</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Sagittis porta velit, id tincidunt enim pretium cras accumsan fringilla eget ipsum.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module pull-quote">
              <blockquote>
                <p><span>“&#8230;</span> for going out, I found, was really going in<span>.”</span></p>
                <div className="author">John Muir</div>
              </blockquote>
            </div>

            <div className="module featured-img unique-img-01">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/home-06.jpg" alt="" /></figure>
              <div className="img-text">
                <div className="flex-grid text-wrap">
                  <div>
                    <div className="flex-grid caption">
                      <div>
                        <div className="image-caption-width">
                          <p>Above</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-desc-width">
                          <p>Sagittis porta velit, id tincidunt enim pretium cras accumsan fringilla eget ipsum.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-grid copy">
                      <div>
                        <div className="image-copy-title-width">
                          <p>Place</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-copy-width">
                          <p>In times where the vitality of nature is more valuable than ever, what does it mean to honour such a site with integrity? For Governors Point, it is of paramount importance that access must come with a clear covenant that nature is in charge. With this in mind, 98 acres of the 125 acre-peninsula has been donated for a dollar to the local Whatcom Land Trust as a nature reserve.</p>
                          <p>Wild hiking trails will allow the local community to access the forests, beaches and raw beauty of Governors Point.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/home-07.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/home-08.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs cols-img-map text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-12 col-md-6 col-map">
                  <figure>
                    <div className="map-wrap">
                      <div className="ie-map"><Map className="map-area" /></div>
                      <MapNorth className="map-north" />
                    </div>
                    <MapKey className="map-key" />
                  </figure>
                </div>
                <div className="col-6 offset-6 offset-md-0 col-img">
                  <figure><img src="/img/home-09.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/home-10.jpg" alt="" /></figure>
            </div>

            <div className="module pull-quote">
              <blockquote>
                <p>Governors Point is a 125-acre peninsula with a 98-acre nature reserve and 16 homesites</p>
              </blockquote>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/home-11.jpg" alt="" /></figure>
              <div className="img-text">
                <div className="flex-grid text-wrap">
                  <div>
                    <div className="flex-grid caption">
                      <div>
                        <div className="image-caption-width">
                          <p>Above</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-desc-width">
                          <p>Sagittis porta velit, id tincidunt enim pretium cras accumsan fringilla eget ipsum.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex-grid copy">
                      <div>
                        <div className="image-copy-title-width">
                          <p>Architecture</p>
                        </div>
                      </div>
                      <div>
                        <div className="image-copy-width">
                          <p>Vancouver architect Omer Arbel has designed a blueprint for the residences following a covenant of land first restrictions to ensure any intervention is careful and consistent.</p>
                          <p>Arbel's nature first approach is to integrate the architecture into the landscape with a balance of discretion and character. Residences will be situated along the coast of peninsula, with landward facades buried into the ground, subtly hidden in the glade.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/home-12.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/home-13.jpg" alt="" /></figure>
                </div>
              </div>
              <div className="img-text">
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Left</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Donec sagittis porta velit, id tincidunt enim pretium cras varius accumsan fringilla.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-grid caption">
                  <div>
                    <div className="image-caption-width">
                      <p>Right</p>
                    </div>
                  </div>
                  <div>
                    <div className="image-desc-width">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing. Pretium cras varius accumsan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module featured-img unique-img-03">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/home-14.jpg" alt="" /></figure>
            </div>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
